<template>
  <div class="dock-five-six w100 flex flex-dir--top h100">
    <breadcrumb
      :title="$route.query.line || $t('main.head.produceLine')"
      :childTitle="$t('common.dock56FDrill')"
      @goBackPage="goBackPage"
    >
      <div v-if="!previewInfo.isPreview">
        <a-button
          @click.native="handleOpenChangeRecord"
          id="equipment_five_six_change_record"
          class="mr20"
        >
          修改记录
        </a-button>
        <a-button
          @click.native="saveDockFiveSix"
          :disabled="!ableSaveSetting"
          type="primary"
          id="equipment_setting_save_btn"
          ><a-icon type="save" />{{ $t('common.saveSetting') }}</a-button
        >
      </div>
      <div v-if="previewInfo.isApply">
        <a-button
          @click.native="saveDockFiveSix"
          :disabled="!ableSaveSetting"
          type="primary"
          id="equipment_setting_save_btn"
          ><a-icon type="save" />应用设置</a-button
        >
      </div>
    </breadcrumb>
    <div class="wrap pb20 pt20 h100 y-auto">
      <div class="equip-type">
        <span class="head-title">{{ $t('dock56Drill.equipType') }}</span>
        <div class="content">
          <!-- <span class="small-title">设备类型：</span> -->
          <a-radio-group v-model="dockType" :disabled="dock56FDrill_customFile">
            <a-radio value="fiveDock" id="fiveSix_setting_five_radio"
              ><span id="fiveSix_setting_five_radio_label">{{
                $t('dock56Drill.fiveDock')
              }}</span></a-radio
            >
            <a-radio value="sixDock" id="fiveSix_setting_six_radio"
              ><span id="fiveSix_setting_six_radio_label">{{
                $t('dock56Drill.sixDock')
              }}</span></a-radio
            >
          </a-radio-group>
        </div>
      </div>
      <div>
        <div class="file-format">
          <span class="head-title">{{ $t('dock56Drill.fileType') }}</span>
          <div class="content flex">
            <div class="relative">
              <span class="small-title"
                >{{ $t('dock56Drill.fileType') }}：</span
              >
              <a-select
                :disabled="dock56FDrill_customFile"
                style="width: 330px"
                :getPopupContainer="
                  (triggerNode) => {
                    return triggerNode.parentNode || document.body
                  }
                "
                :filter-option="
                  (input, option) =>
                    option.componentOptions.children[0].text
                      .toLowerCase()
                      .trim()
                      .includes(input.toLowerCase())
                "
                show-search
                default-value="xml"
                v-model="selectedDockFile"
                showArrow
                @change="changeSelectedDockFile"
                id="fiveSix_setting_dockFile_select"
              >
                <a-select-option
                  :value="item.dock56Format"
                  v-for="(item, index) in dockFiveSixList"
                  :key="index"
                  :title="item.modelName"
                  :id="`fiveSix_setting_dockFile_select_option_${index}`"
                  >{{ item.modelName }}</a-select-option
                >
              </a-select>
              <span v-if="isNotOnline" class="not-online">{{
                $t('dock56Drill.templateOffline')
              }}</span>
              <div v-if="showFiveSixDefault" class="tip mt8">
                当前模板对于部分孔槽分流功能不生效，推荐使用自定义模板
              </div>
              <div class="mt20">
                <span class="small-title"
                  >{{ $t('dock56Drill.showExample') }}：</span
                >
                <a-checkbox
                  v-model="showSample"
                  id="fiveSix_setting_showSample_checkbox"
                ></a-checkbox>
              </div>
            </div>
            <div class="remark-info flex flex-dir--top ml20 fs14">
              <span class="note">{{ $t('dock56Drill.conNoticeTip') }}</span>
              <span>{{ $t('dock56Drill.conNoticeTip1') }}</span>
              <span>{{ $t('dock56Drill.conNoticeTip2') }}</span>
              <span v-html="$t('dock56Drill.conNoticeTip3')"></span>
              <span v-html="$t('dock56Drill.conNoticeTip4')"></span>
            </div>
          </div>
        </div>
        <div class="common-setting" v-show="showSample">
          <span class="head-title">{{ $t('dock56Drill.example') }}</span>
          <div class="content">
            <DockFiveSixSample :file-type="selectedDockFile" />
          </div>
        </div>
        <div class="common-setting">
          <span class="head-title">{{ $t('dock56Drill.commonSetting') }}</span>
          <div class="content">
            <span class="small-title"
              >{{ $t('dock56Drill.throughHoleSetting') }}：</span
            >
            <a-checkbox
              v-model="dock6FDrill_towSideConHole"
              id="fiveSix_setting_towSideConHole_checkbox"
            ></a-checkbox>
          </div>
          <div class="content">
            <span class="small-title"
              >{{ $t('dock56Drill.throughSlotSetting') }}：</span
            >
            <a-checkbox
              v-model="dock56FDrill_twoSideConSlot"
              id="fiveSix_setting_towSideConSlot_checkbox"
            ></a-checkbox>
          </div>
          <div class="content">
            <span class="small-title">{{
              $t('dock56Drill.nonHolesNotFiles')
            }}</span>
            <a-checkbox
              v-model="non_holes_not_files"
              id="fiveSix_setting_non_holes_not_files_checkbox"
            ></a-checkbox>
          </div>
          <div class="content front-back-symbol">
            <div>
              <span class="small-title">{{
                $t('dock56Drill.frontFileIdentifier')
              }}</span>
              <a-input
                v-model="front_file_identifier"
                @change="changeFileIdentifierFront(front_file_identifier)"
                id="fiveSix_setting_front_file_identifier_input"
              ></a-input>
            </div>
            <div>
              <span class="small-title">{{
                $t('dock56Drill.oppositeFileIdentifier')
              }}</span>
              <a-input
                v-model="opposite_file_identifier"
                @change="changeFileIdentifierOpposite(opposite_file_identifier)"
                id="fiveSix_setting_opposite_file_identifier_input"
              ></a-input>
            </div>
            <span>{{ $t('dock56Drill.oppositeFileIdentifierTips') }}</span>
          </div>
          <div class="content">
            <span class="small-title"
              >{{ $t('dock56Drill.arcDescription') }}：</span
            >
            <a-radio-group v-model="arc_description">
              <a-radio
                value="line_points"
                id="fiveSix_setting_arc_description_line_points_radio"
                ><span
                  id="fiveSix_setting_arc_description_line_points_radio_label"
                  >{{ $t('dock56Drill.linePoints') }}</span
                ></a-radio
              >
              <a-radio
                value="arc_command"
                id="fiveSix_setting_arc_description_arc_command_radio"
                ><span
                  id="fiveSix_setting_arc_description_arc_command_radio_label"
                  >{{ $t('dock56Drill.arcCommand') }}</span
                ></a-radio
              >
            </a-radio-group>
          </div>
          <div class="content">
            <span class="small-title">{{
              $t('dock56Drill.slotWidthSplit')
            }}</span>
            <a-checkbox
              v-model="split_slot"
              id="fiveSix_setting_split_slot_checkbox"
            ></a-checkbox>
            <span class="small-title partition">{{
              $t('dock56Drill.slotSplitWidth')
            }}</span>
            <a-input
              v-model="single_width"
              @change="changeSingleWidth(single_width)"
              id="fiveSix_setting_single_width_input"
            ></a-input>
            <span class="partition hintColor">{{ $t('dock56Drill.mm') }}</span>
            <img
              :src="require('@/assets/banlu_dismantle.png')"
              alt="无法加载"
              class="partition"
            />
          </div>
          <div class="content flex flex-cross--center">
            <span class="small-title">{{
              $t('dock56Drill.isNotMergeSlot')
            }}</span>
            <a-checkbox
              v-model="isNotMergeSlot"
              id="dockFiveSix_isNotMergeSlot_checkbox"
            ></a-checkbox>
            <img
              src="https://cdn.eggrj.com/bluenAssets/image/dockFiveSix_slotPunch.png"
              alt="无法加载"
              class="slot-tuglie ml15"
            />
            <span class="ml15">({{ $t('dock56Drill.slotPunchTip') }})</span>
          </div>
          <transition name="adjacent-size-transition">
            <div class="content" v-if="isNotMergeSlot">
              <span class="small-title">{{ $t('dock56Drill.sizeRange') }}</span>
              <a-input
                id="dockFiveSix_adjacentSize_input"
                v-model="adjacentSize"
                style="margin: 0 4px"
                @change="handleChangeAdjacentSize(adjacentSize)"
              ></a-input>
              <span>mm</span>
            </div>
          </transition>
          <el-tooltip
            :disabled="!is_front_edge_fixed"
            :content="$t('dock56Drill.enableWorningTip')"
            placement="top"
          >
            <div class="content">
              <span class="small-title">{{
                $t('dock56Drill.longDirection')
              }}</span>
              <a-checkbox
                :disabled="is_front_edge_fixed || automation"
                class="check"
                v-model="is_output56FDrillLayoutDir"
                id="fiveSix_setting_direction_check"
              ></a-checkbox>
              <a-radio-group
                v-model="output56FDrillLayoutDir"
                :disabled="
                  is_front_edge_fixed ||
                  !is_output56FDrillLayoutDir ||
                  automation
                "
              >
                <a-radio
                  value="Horizontal"
                  id="fiveSix_setting_horizontal_radio"
                  >{{ $t('dock56Drill.horizontal') }}</a-radio
                >
                <a-radio value="Vertical" id="fiveSix_setting_vertical_radio">{{
                  $t('dock56Drill.vertical')
                }}</a-radio>
              </a-radio-group>
            </div>
          </el-tooltip>
          <el-tooltip
            :disabled="!is_front_edge_fixed"
            :content="$t('dock56Drill.enableWorningTip')"
            placement="top"
          >
            <div class="content shape-texdir">
              <span class="small-title">{{
                $t('dock56Drill.slotDirection')
              }}</span>
              <a-checkbox
                :disabled="is_front_edge_fixed"
                class="check"
                v-model="selectedOutput56DrillSoltDir"
                id="fiveSix_setting_horizontal_radio"
              ></a-checkbox>
              <a-radio-group
                v-model="output56FDrillSlotDir"
                :disabled="!selectedOutput56DrillSoltDir || is_front_edge_fixed"
              >
                <a-radio
                  value="default"
                  id="fiveSix_setting_output56FDrillSlotDir_default_radio"
                  >{{ $t('dock56Drill.default') }}</a-radio
                >
                <a-radio
                  value="frontLeft"
                  id="fiveSix_setting_output56FDrillSlotDir_frontLeft_radio"
                  >{{ $t('dock56Drill.frontLeft') }}</a-radio
                >
                <a-radio
                  value="frontRight"
                  id="fiveSix_setting_output56FDrillSlotDir_frontRight_radio"
                  >{{ $t('dock56Drill.frontRight') }}</a-radio
                >
                <a-radio
                  value="backLeft"
                  id="fiveSix_setting_output56FDrillSlotDir_backLeft_radio"
                  >{{ $t('dock56Drill.backLeft') }}</a-radio
                >
                <a-radio
                  value="backRight"
                  id="fiveSix_setting_output56FDrillSlotDir_backRight_radio"
                  >{{ $t('dock56Drill.backRight') }}</a-radio
                >
              </a-radio-group>
              <div class="drill-slot-dir">
                <img
                  :src="require('@/assets/output56FDrillSlotDir.png')"
                  alt=""
                />
              </div>
              <!-- 生效范围 -->
              <div>
                <span class="mr10">生效范围：板件长边≤</span>
                <a-input
                  :value="long_edge_rotate_range"
                  :disabled="
                    is_front_edge_fixed || !selectedOutput56DrillSoltDir
                  "
                  @input="handleInputCheck"
                  id="fiveSix_setting_longEdgeRotateRange_input"
                ></a-input>
                <span class="ml15"
                  >(当开启板件长边摆放且板子长边在该生效范围内会优先判断槽和异形放置位置)</span
                >
              </div>
            </div>
          </el-tooltip>
          <!-- 前封边摆放方向 -->
          <div class="content">
            <div>
              <span class="small-title">{{
                $t('dock56Drill.frontDirection')
              }}</span>
              <a-checkbox
                class="check ml10"
                v-model="is_front_edge_fixed"
                :disabled="automation"
              ></a-checkbox>
              <span class="tip">{{ $t('dock56Drill.enableTip') }}</span>
            </div>
            <div class="mt10">
              <span class="small-title">{{ $t('dock56Drill.fixedOn') }}</span>
              <a-radio-group
                v-model="front_edge_fixed_direction"
                :disabled="!is_front_edge_fixed"
              >
                <a-radio value="front" id="fiveSix_setting_fixed_front_radio">{{
                  $t('dock56Drill.front')
                }}</a-radio>
                <a-radio value="back" id="fiveSix_setting_fixed_back_radio">{{
                  $t('dock56Drill.back')
                }}</a-radio>
                <a-radio value="left" id="fiveSix_setting_fixed_left_radio">{{
                  $t('dock56Drill.left')
                }}</a-radio>
                <a-radio value="right" id="fiveSix_setting_fixed_right_radio">{{
                  $t('dock56Drill.right')
                }}</a-radio>
              </a-radio-group>
            </div>
          </div>
          <div class="content">
            <span class="small-title"
              >{{ $t('dock56Drill.huahuaLine') }}：</span
            >
            <a-checkbox class="check ml10" v-model="automation"></a-checkbox>
          </div>
          <!-- 坐标值保留位数 -->
          <div class="content">
            <span class="small-title">{{
              $t('dock56Drill.coordinateValue')
            }}</span>
            <el-input
              v-model.number="output56FDrillDecimalNum"
              oninput="value=value.replace(/[^0-9]/g,'')"
              id="fiveSix_setting_output56FDrillDecimalNum_input"
            />
            <span>{{ $t('dock56Drill.decimalPlaces') }}</span>
          </div>
        </div>
      </div>
      <!-- 五面钻特殊参数 -->
      <div v-if="showFiveDockSpecial">
        <div class="five-special-setting">
          <span class="head-title">{{
            $t('dock56Drill.specialParameter')
          }}</span>
          <div class="content">
            <span class="small-title">{{
              $t('dock56Drill.output5FDrillSlotKnifeCode')
            }}</span>
            <a-input
              v-model="output5FDrillSlotKnifeCode"
              id="fiveSix_setting_output5FDrillSlotKnifeCode_input"
            ></a-input>
          </div>
          <div class="content">
            <span class="small-title">{{
              $t('dock56Drill.output5FDrillShapeKnifeCode')
            }}</span>
            <a-input
              v-model="output5FDrillShapeKnifeCode"
              id="fiveSix_setting_output5FDrillShapeKnifeCode_input"
            ></a-input>
          </div>
          <div class="content display">
            <span class="small-title">{{
              $t('dock56Drill.plankTexture')
            }}</span>
            <div class="tex-dir">
              <span class="small-title">{{ $t('common.reverse') }}=</span>
              <a-input
                v-model="output5FDrillNormalTexdirCode"
                id="fiveSix_setting_output5FDrillNormalTexdirCode_input"
              ></a-input>
            </div>
            <div class="tex-dir">
              <span class="small-title">{{ $t('common.normal') }}=</span>
              <a-input
                v-model="output5FDrillReverseTexdirCode"
                id="fiveSix_setting_output5FDrillReverseTexdirCode_input"
              ></a-input>
            </div>
          </div>
          <div class="content">
            <span class="small-title">{{ $t('dock56Drill.offset') }}</span>
            <a-input
              v-model="output5FDrillShapeKnifeOffset"
              id="fiveSix_setting_output5FDrillShapeKnifeOffset_input"
            ></a-input>
          </div>
          <div class="content" v-if="selectedDockFile == 'mpr'">
            <span class="mr20 fs16">{{ $t('dock56Drill.mprConHoleDir') }}</span>
            <a-radio-group v-model="dock56FDrillMprConHoleDir">
              <a-radio
                :value="false"
                id="fiveSix_setting_dock56FDrillMprConHoleDir_false_radio"
                >+{{ $t('dock56Drill.plankTop') }}</a-radio
              >
              <a-radio
                :value="true"
                id="fiveSix_setting_dock56FDrillMprConHoleDir_trueradio"
                >+{{ $t('dock56Drill.plankBottom') }}</a-radio
              >
            </a-radio-group>
          </div>
        </div>
      </div>
      <div class="" v-if="showSixDockSpecial">
        <div class="six-special-setting">
          <span class="head-title">{{
            $t('dock56Drill.specialParameter')
          }}</span>
          <div class="content" v-if="selectedDockFile == 'ban'">
            <span class="small-title">{{ $t('dock56Drill.codeInfo') }}</span>
            <a-checkbox
              v-model="dock6FDrill_code"
              id="fiveSix_setting_dock6FDrill_code_checkbox"
            ></a-checkbox>
          </div>
          <div class="content" v-if="selectedDockFile == 'ban'">
            <span class="small-title">{{ $t('dock56Drill.bomFile') }}</span>
            <a-checkbox
              v-model="utf8bom"
              id="fiveSix_setting_utf8bom_checkbox"
            ></a-checkbox>
          </div>
          <div class="content" v-if="selectedDockFile == 'ban'">
            <span class="small-title">{{ $t('dock56Drill.plankHeight') }}</span>
            <a-radio-group v-model="dock6FDrill_plankHeight">
              <a-radio
                value="hight"
                id="fiveSix_setting_dock6FDrill_plankHeight_hight_radio"
                >Hight</a-radio
              >
              <a-radio
                value="height"
                id="fiveSix_setting_dock6FDrill_plankHeight_height_radio"
                >Height</a-radio
              >
            </a-radio-group>
          </div>
          <div class="content" v-if="selectedDockFile == 'ban'">
            <span class="small-title">{{ $t('dock56Drill.plankNum') }}</span>
            <a-checkbox-group v-model="dock6FDrill_plankCode">
              <a-checkbox
                value="Code"
                id="fiveSix_setting_dock6FDrill_plankCode_code_checkbox"
                >Code</a-checkbox
              >
              <a-checkbox
                value="Code1"
                id="fiveSix_setting_dock6FDrill_plankCode_code1_checkbox"
                >Code1</a-checkbox
              >
            </a-checkbox-group>
          </div>
          <div class="content" v-if="selectedDockFile == 'ban'">
            <span class="small-title">{{ $t('dock56Drill.backZpoint') }}</span>
            <a-radio-group v-model="dock6FDrill_zValue">
              <a-radio
                :value="0"
                id="fiveSix_setting_dock6FDrill_zValue_0_radio"
                >-({{ $t('dock56Drill.plankThick') }}-{{
                  $t('dock56Drill.slotDeep')
                }})</a-radio
              >
              <a-radio
                :value="1"
                id="fiveSix_setting_dock6FDrill_zValue_1_radio"
                >-({{ $t('dock56Drill.slotDeep') }})</a-radio
              >
            </a-radio-group>
          </div>
          <div class="content need-bottom" v-if="selectedDockFile == 'mpr'">
            <div class="mb10">
              <span class="small-title">{{
                $t('dock56Drill.outputMprFile')
              }}</span>
              <a-radio-group v-model="dock6FOutputMprFile">
                <a-radio
                  value="mpr"
                  id="fiveSix_setting_dock6FOutputMprFile_mpr_radio"
                  >{{ $t('dock56Drill.oldMPR') }}</a-radio
                >
                <a-radio
                  value="newMpr"
                  id="fiveSix_setting_dock6FOutputMprFile_newMpr_radio"
                  >{{ $t('dock56Drill.newMPR') }}</a-radio
                >
              </a-radio-group>
            </div>
            <div v-if="dock6FOutputMprFile === 'mpr'">
              <span class="mr20 fs16">{{
                $t('dock56Drill.mprFileZside')
              }}</span>
              <a-radio-group v-model="dock56FDrillMprConHoleDir">
                <a-radio
                  :value="false"
                  id="fiveSix_setting_dock56FDrillMprConHoleDir_false_radio"
                  >+{{ $t('dock56Drill.plankTop') }}</a-radio
                >
                <a-radio
                  :value="true"
                  id="fiveSix_setting_dock56FDrillMprConHoleDir_true_radio"
                  >+{{ $t('dock56Drill.plankBottom') }}</a-radio
                >
              </a-radio-group>
            </div>
          </div>
        </div>
      </div>
    </div>
    <m-lock-modal
      :visible.sync="isShowVisible"
      :status.sync="lockStatus"
      :tempStatus.sync="tempStatus"
      :verifyPassword="verifyPassword"
      :lineName="$route.query.line"
      @getLockPassword="handleGetLockPassword"
      @handleChangePasswordState="handleChangePasswordState"
    ></m-lock-modal>
    <!-- 修改记录弹窗 -->
    <m-change-record-modal
      :visible.sync="showRecordModal"
      operate_equipment="dock56FDrill"
      @success="handleSuccess"
      ref="recordModalRef"
    />
  </div>
</template>

<script>
import { getLockStatus } from '@/apis/productionLineLock'
import breadcrumb from '@/components/topBreadCrumb.vue'
import store from '@/store'
import { genFinalRecord, genFinalRecordPromise } from '@/util/commonFuncs'
import {
  dockFiveSixFormTextObj,
  dockFiveSixFormValueObj,
} from '@/util/operateRecordConfigure/dockFiveSixConfigure'
import { saveDataJsonToOssForRecord } from '@/util/saveFile'
import MChangeRecordModal from '@/views/equipment/component/m-change-record-modal.vue'
import MLockModal from '@/views/equipment/component/m-lock-modal.vue'
import { mapState } from 'vuex'

import DockFiveSixSample from './component/dockFiveSixSample.vue'

export default {
  components: {
    breadcrumb,
    DockFiveSixSample,
    MLockModal,
    MChangeRecordModal,
  },
  data() {
    return {
      lockStatus: 1, // 锁状态
      verifyPassword: true,
      tempStatus: 1, //默认状态
      isShowVisible: false,
      // 是否使用文件自定义
      dock56FDrill_customFile: false,
      // 是否为天工
      showTiangong: false,
      // 记录选择的五六面钻文件
      selectedDockFile: '',
      // 记录五面钻的文件类型
      selectFiveList: [
        { name: 'XML', value: 'xml' },
        { name: 'XML2', value: 'xml2' },
        { name: 'XML3', value: 'xml3' },
        { name: 'MPR', value: 'mpr' },
      ],
      // 记录选中的五面钻文件类型
      dock5FDrill_fileType: 'xml',
      // 记录六面钻的文件类型
      selectSixList: [
        { name: 'BAN', value: 'ban' },
        { name: 'XML1', value: 'xml1' },
        { name: 'XML3', value: 'xml3' },
        { name: 'MPR', value: 'mpr' },
      ],
      // 记录五六面钻的文件类型
      dockFiveSixList: [],
      // 记录选中的六面钻文件类型
      dock6FDrill_fileType: 'ban',
      // 记录选中的刀具
      dockType: 'fiveDock',
      is_output56FDrillLayoutDir: true,
      // 板件长边摆放方向
      output56FDrillLayoutDir: 'Vertical',
      // 五六面钻槽和异形摆放优
      selectedOutput56DrillSoltDir: true,
      // 五六面钻槽或异形放置方向
      output56FDrillSlotDir: 'default',
      // 生效范围:
      long_edge_rotate_range: 1200,
      // 桦桦自动线
      automation: false,
      // 坐标值保留位数
      output56FDrillDecimalNum: '2',
      // xml2铣槽刀具编号
      output5FDrillSlotKnifeCode: '',
      // xml2铣型刀具编号
      output5FDrillShapeKnifeCode: '',
      // xml2板件纹理 横纹
      output5FDrillReverseTexdirCode: '',
      // xml2板件纹理 竖纹
      output5FDrillNormalTexdirCode: '',
      output5FDrillShapeKnifeOffset: '',
      // 通孔分双面打通
      dock6FDrill_towSideConHole: false,
      // 通槽分双面打通
      dock56FDrill_twoSideConSlot: false,
      // 矩形版没有孔槽不输出文件
      non_holes_not_files: false,
      // ban格式文件包含Code信息
      dock6FDrill_code: false,
      // 输出bom格式文件
      utf8bom: false,
      // 板件高度描述
      dock6FDrill_plankHeight: '',
      // 板号描述
      dock6FDrill_plankCode: [],
      // 反面槽Z坐标
      dock6FDrill_zValue: 0,
      // mpr文件类型
      dock6FOutputMprFile: 'mpr',
      // mpr侧面孔Z坐标基准面
      dock56FDrillMprConHoleDir: true,
      // 正面文件标识
      front_file_identifier: '',
      // 反面文件标识
      opposite_file_identifier: 'K',
      // 圆弧描述方式
      arc_description: 'arc_command',
      // 记录是否可以进行保存
      ableSaveSetting: false,
      // 记录当前五六面钻模板是否被下线
      isNotOnline: false,
      // 宽槽拆解为多条窄槽加工
      split_slot: true,
      // 拆解后单条槽宽度
      single_width: '',
      oriFormData: {},
      // 是否显示样例
      showSample: false,
      // 固定前封边
      is_front_edge_fixed: false,
      // 前封边固定在什么方向
      front_edge_fixed_direction: 'left',
      //相邻等宽等深槽是否拉通处理
      isNotMergeSlot: false,
      //等宽等深槽相邻尺寸大小
      adjacentSize: 3,
      // 后端使用数量字段要求
      old_file_type: '',
      // 设置一个辅助值限制输入
      limitValue: '',
      showRecordModal: false,
    }
  },
  methods: {
    // 生效范围的输入检测
    handleInputCheck(e) {
      // 最多允许输入五位整数和两位小数，数据范围大于等于0
      // 正则表达式
      const reg = /^(\d{0,5})(\.(\d{0,2}))?$/g
      if (reg.test(e.target.value)) {
        this.limitValue = e.target.value
      }
      this.long_edge_rotate_range = this.limitValue
      // 修改输入框的值
      e.target.value = this.long_edge_rotate_range
    },
    getPopupContainer(triggerNode) {
      return triggerNode.parentElement || document.body
    },
    async handleGetLockPassword() {
      await this.saveDockFiveSix()
    },
    handleChangePasswordState(state) {
      this.verifyPassword = state
    },
    changeFileIdentifierFront(value) {
      this.front_file_identifier = value.replace(/[^\w]|[//]/g, '')
    },
    changeFileIdentifierOpposite(value) {
      this.opposite_file_identifier = value.replace(/[^\w]|[//]/g, '')
    },
    changeSingleWidth(value) {
      this.single_width = value
        .replace(/\D*(\d{0,3})\d*(\.?)(\d{0,3})\d*/, '$1$2$3')
        .replace(/[^\d\.]/g, '')
    },
    handleChangeAdjacentSize(value) {
      this.adjacentSize = value.replace(/^[^0-9]||[/.]/g, '')
      if (value.includes('.')) {
        const res = value.split('.')
        if (res.length > 2) {
          this.adjacentSize = res[0] + '.'
        }
        if (res[1].length > 1) {
          this.adjacentSize = res[0] + '.' + res[1][0]
        }
      } else {
        this.adjacentSize = this.adjacentSize[0]
      }
    },
    toCustomFile() {
      this.$router.push({
        path: '/customFile',
        query: {
          setting_id: this.$route.query.setting_id,
          line: this.$route.query.line,
        },
      })
    },
    goBackPage() {
      this.nowPath = ''
      let path = ''
      if (this.showTiangong) {
        path = '/equipment?from=tiangong'
      } else {
        path = '/equipment'
      }
      if (this.previewInfo.isPreview) {
        path = `${path}-preview`
      }

      this.$router.push(path)
    },
    // 输入检测
    // inputCheck(val){
    //   // this[val] = this[val].replace(/[^\d]/g, "") // 去除数字和小数点以外的数字
    // },
    // 修改选择的文件格式时, 更改状态
    changeSelectedDockFile() {
      if (this.isNotOnline && this.selectedDockFile != '') {
        this.isNotOnline = false
      }
    },
    genCompareData(current, ori) {
      const selectedDockFile = {}
      this.dockFiveSixList.forEach((item) => {
        selectedDockFile[item.dock56Format] = item.modelName
      })
      const dockFiveSixFormValueObjTemp = {
        ...dockFiveSixFormValueObj,
        // selectedDockFile: selectedDockFile,
      }
      let specailParamsKeys = []
      if (
        this.selectedDockFile == 'xml2' &&
        this.dockType == 'fiveDock' &&
        !this.dock56FDrill_customFile
      ) {
        specailParamsKeys = [
          'output5FDrillSlotKnifeCode',
          'output5FDrillShapeKnifeCode',
          'output5FDrillNormalTexdirCode',
          'output5FDrillReverseTexdirCode',
          'output5FDrillShapeKnifeOffset',
        ]
      }

      if (this.dockType == 'sixDock' && !this.dock56FDrill_customFile) {
        specailParamsKeys = [
          'dock6FDrill_code',
          'utf8bom',
          'dock6FDrill_plankHeight',
          'dock6FDrill_plankCode',
          'dock6FDrill_zValue',
        ]
      }
      if (this.selectedDockFile == 'mpr') {
        specailParamsKeys = ['dock6FOutputMprFile']
        if (
          (this.dock6FOutputMprFile == 'mpr' && this.dockType == 'sixDock') ||
          this.dockType == 'fiveDock'
        ) {
          specailParamsKeys = [
            'dock6FOutputMprFile',
            'dock56FDrillMprConHoleDir',
          ]
        }
        if (this.dockType == 'fiveDock' && this.selectedDockFile == 'mpr') {
          specailParamsKeys = [
            'output5FDrillSlotKnifeCode',
            'output5FDrillShapeKnifeCode',
            'output5FDrillNormalTexdirCode',
            'output5FDrillReverseTexdirCode',
            'output5FDrillShapeKnifeOffset',
            'dock56FDrillMprConHoleDir',
          ]
        }
      }
      const compareMsg = [
        {
          title: '设备类型',
          keys: ['dockType'],
        },
        {
          title: '生成文件类型',
          keys: ['selectedDockFile'],
        },
        {
          title: '通用参数设置',
          keys: [
            'dock6FDrill_towSideConHole',
            'dock56FDrill_twoSideConSlot',
            'non_holes_not_files',
            'front_file_identifier',
            'opposite_file_identifier',
            'arc_description',
            'split_slot',
            'single_width',
            'output56FDrillLayoutDir',
            'is_output56FDrillLayoutDir',
            'selectedOutput56DrillSoltDir',
            'output56FDrillSlotDir',
            'output56FDrillDecimalNum',
            'is_front_edge_fixed',
            'front_edge_fixed_direction',
            'isNotMergeSlot',
            'adjacentSize',
            'automation',
            'long_edge_rotate_range',
          ],
        },
        {
          title: '特殊参数设置',
          keys: specailParamsKeys,
        },
        {
          title: '根据订单分为不同文件夹',
          keys: ['group_file'],
        },
      ]
      return {
        type: 'form',
        current, // 当前表单数据
        ori, // 原始表单数据
        compareMsg, // 要比较的内容
        formTextObj: dockFiveSixFormTextObj, // key对应的意思
        formValueObj: dockFiveSixFormValueObjTemp, // value对应的意思
      }
    },
    async genFinalRecordFn(current, ori, saveData, callback = null) {
      let recordData = {
        key: '五六面钻', // 操作记录最外层的名字，比如：雕刻机
        operateArr: [
          // 应对多个操作页面时，比如雕刻机的操作页面
          {
            key: '',
            dataArr: [
              // 氛围表单和table两种
              this.genCompareData(current, ori),
            ],
          },
        ],
      }
      // genFinalRecord(recordData, 'equipment_docking', () => {
      //   if (this.$route.query.setting_id) {
      //     this.getAllDock56File()
      //   }
      // })
      try {
        const res = await genFinalRecordPromise(recordData, 'equipment_docking')
        if (res) {
          const { record, finalId, action_type } = res
          saveDataJsonToOssForRecord(
            record,
            action_type,
            {
              id: finalId,
              operate_equipment: 'dock56FDrill',
              save_data: saveData,
              setting_id: this.$route.query.setting_id,
              operatorPhone: this.previewInfo.operatorPhone,
            },
            () => {
              if (!this.previewInfo?.isApply) {
                this.getAllDock56File()
              }
              callback && callback()
            }
          )
        }
      } catch (error) {
        this.$message({
          message: '生成操作记录失败',
          type: 'error',
        })
      }
    },
    // 保存五六面钻设置
    saveDockFiveSix() {
      // if (this.selectedDockFile == '') {
      //   this.$message({
      //     message: '请选择生成文件类型!',
      //     type: 'error',
      //   })
      //   return
      // }
      // 校验板件长边输入的正确性 需要在前封边关闭且五/六面钻槽或异形放置方向勾选时才生效
      if (
        this.long_edge_rotate_range === '' &&
        this.selectedOutput56DrillSoltDir &&
        !this.is_front_edge_fixed
      ) {
        // 不合法的输入，不让保存，弹出提示并滑动定位到指定位置
        let dom = document.getElementById(
          'fiveSix_setting_longEdgeRotateRange_input'
        )
        dom.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
        return this.$message({
          message: '板件长边范围不允许为空',
          type: 'warning',
        })
      }
      // 判断锁定状态，打开弹窗
      // 根据获取的状态 锁定状态
      this.lockStatus = this.tempStatus
      let formData = {
        dock5FDrill: this.dockType == 'fiveDock' ? true : false,
        dock6FDrill: this.dockType == 'fiveDock' ? false : true,
        selectedOutput56DrillSoltDir: this.selectedOutput56DrillSoltDir,
        is_output56FDrillLayoutDir: this.is_output56FDrillLayoutDir,
        output56FDrillSlotDir: this.output56FDrillSlotDir,
        long_edge_rotate_range:
          this.long_edge_rotate_range === ''
            ? ''
            : Number(this.long_edge_rotate_range),
        automation: this.automation,
        output56FDrillDecimalNum: Number(this.output56FDrillDecimalNum),
        output56FDrillLayoutDir: this.output56FDrillLayoutDir,
        output5FDrillSlotKnifeCode: this.output5FDrillSlotKnifeCode,
        output5FDrillShapeKnifeCode: this.output5FDrillShapeKnifeCode,
        output5FDrillNormalTexdirCode: this.output5FDrillNormalTexdirCode,
        output5FDrillShapeKnifeOffset: this.output5FDrillShapeKnifeOffset,
        output5FDrillReverseTexdirCode: this.output5FDrillReverseTexdirCode,
        dock5FDrill_fileType: this.dock5FDrill_fileType,
        dock6FDrill_fileType: this.dock6FDrill_fileType,
        dock6FDrill_towSideConHole: this.dock6FDrill_towSideConHole,
        dock56FDrill_twoSideConSlot: this.dock56FDrill_twoSideConSlot,
        non_holes_not_files: this.non_holes_not_files,
        dock6FDrill_code: this.dock6FDrill_code,
        utf8bom: this.utf8bom,
        dock6FDrill_plankHeight: this.dock6FDrill_plankHeight,
        dock6FDrill_plankCode: this.dock6FDrill_plankCode,
        dock6FOutputMprFile: this.dock6FOutputMprFile,
        dock6FDrill_zValue: this.dock6FDrill_zValue,
        dock56FDrill_customFile: this.dock56FDrill_customFile,
        front_file_identifier: this.front_file_identifier,
        opposite_file_identifier: this.opposite_file_identifier,
        arc_description: this.arc_description,
        split_slot: this.split_slot,
        single_width: this.single_width,
        group_file: this.group_file ? 'split_by_order' : 'none',
        is_front_edge_fixed: this.is_front_edge_fixed,
        front_edge_fixed_direction: this.front_edge_fixed_direction,
        dock56FDrillMprConHoleDir: this.dock56FDrillMprConHoleDir,
        isNotMergeSlot: this.isNotMergeSlot,
        adjacentSize: this.adjacentSize,
        old_file_type: this.old_file_type,
      }
      formData.dock5FDrill_fileType = this.selectedDockFile
      formData.dock6FDrill_fileType = this.selectedDockFile
      let data = {
        setting_id: this.$route.query.setting_id,
        data: formData,
        ...store.state.saveInfo,
        platform: 'bluen',
      }
      this.ableSaveSetting = false
      this.$store.commit('setCurrentProductLineName', this.$route.query.line)
      this.$token('/save_dock56F_setting', data, (res) => {
        this.ableSaveSetting = true
        if (res.status == 1) {
          this.isShowVisible = false
          this.$message({
            message: this.$t('common.saveSuccess'),
            type: 'success',
            duration: 1500,
            offset: 70,
          })
          try {
            this.genFinalRecordFn(
              {
                ...formData,
                selectedDockFile: this.selectedDockFile,
                dockType: this.dockType,
              },
              this.oriFormData,
              data
            )
          } catch (error) {
            console.error('error', error)
          }
          if (res.data.phone && res.data.request_ip) {
            let recordData = {
              key: '保存设置',
              operateArr: [
                {
                  key: '生产线设置',
                  dataArr: [
                    {
                      type: 'form',
                      current: {
                        phone: res.data.phone,
                        time: new Date().toLocaleString(),
                        id: store.state.userInfo.id,
                        ip: res.data.request_ip,
                      },
                      ori: {
                        phone: '',
                        time: '',
                        id: '',
                        ip: '',
                      },
                      compareMsg: [
                        {
                          title: '',
                          keys: ['phone', 'time', 'id', 'ip'],
                        },
                      ],
                      formTextObj: {
                        phone: '手机号',
                        time: '保存时间',
                        id: '用户id',
                        ip: '物理地址',
                      },
                      formValueObj: {},
                    },
                  ],
                },
              ],
            }
            genFinalRecord(recordData, 'equipment_docking', '', () => {})
          }
        } else if (res.status == -1) {
          if (store.state.saveInfo.phone) {
            this.$message({
              message: res.msg,
              type: 'error',
              duration: 1500,
              offset: 70,
            })
          }
          this.showSaveModal()
        } else {
          this.$message({
            message: res?.msg || this.$t('lineManager.permissionTip'),
            type: 'error',
            duration: 1500,
            offset: 70,
          })
        }
        store.commit('setSaveInfo', {})
      })
    },
    showSaveModal() {
      this.lockStatus = this.tempStatus
      this.verifyPassword = true
      this.isShowVisible = true
      return
    },
    // 生成formData
    genOriFormData() {
      this.oriFormData = {
        dockType: this.dockType,
        selectedDockFile: this.selectedDockFile,
        dock6FDrill_towSideConHole: this.dock6FDrill_towSideConHole,
        dock56FDrill_twoSideConSlot: this.dock56FDrill_twoSideConSlot,
        non_holes_not_files: this.non_holes_not_files,
        front_file_identifier: this.front_file_identifier,
        opposite_file_identifier: this.opposite_file_identifier,
        arc_description: this.arc_description,
        split_slot: this.split_slot,
        single_width: this.single_width,
        output56FDrillLayoutDir: this.output56FDrillLayoutDir,
        selectedOutput56DrillSoltDir: this.selectedOutput56DrillSoltDir,
        is_output56FDrillLayoutDir: this.is_output56FDrillLayoutDir,
        output56FDrillSlotDir: this.output56FDrillSlotDir,
        long_edge_rotate_range: this.long_edge_rotate_range,
        automation: this.automation,
        output56FDrillDecimalNum: Number(this.output56FDrillDecimalNum),
        output5FDrillSlotKnifeCode: this.output5FDrillSlotKnifeCode,
        output5FDrillShapeKnifeCode: this.output5FDrillShapeKnifeCode,
        output5FDrillNormalTexdirCode: this.output5FDrillNormalTexdirCode,
        output5FDrillShapeKnifeOffset: this.output5FDrillShapeKnifeOffset,
        output5FDrillReverseTexdirCode: this.output5FDrillReverseTexdirCode,
        dock6FDrill_code: this.dock6FDrill_code,
        utf8bom: this.utf8bom,
        dock6FDrill_plankHeight: this.dock6FDrill_plankHeight,
        dock6FDrill_plankCode: this.dock6FDrill_plankCode,
        dock6FDrill_zValue: this.dock6FDrill_zValue,
        dock6FOutputMprFile: this.dock6FOutputMprFile,
        group_file: this.group_file ? 'split_by_order' : 'none',
        is_front_edge_fixed: this.is_front_edge_fixed,
        front_edge_fixed_direction: this.front_edge_fixed_direction,
        dock56FDrillMprConHoleDir: this.dock56FDrillMprConHoleDir,
        isNotMergeSlot: this.isNotMergeSlot,
        adjacentSize: this.adjacentSize,
      }
    },
    // 读取五六面钻所有文件
    getAllDock56File() {
      this.$get(
        '/dock56F_list',
        {
          from: 'user',
        },
        (res) => {
          if (res.status == 1) {
            this.dockFiveSixList = res.data.model_list
            this.getDockFiveSix(this.$route.query.setting_id)
          }
        }
      )
    },
    // 读取五六面钻设置
    getDockFiveSix(id) {
      this.$token(
        '/load_dock56F_setting',
        {
          setting_id: id,
        },
        (res) => {
          if (res.status == 1) {
            this.dealWithFiveSixSetting(res)
            this.genOriFormData()
            // 后台应用生产线时进入
            if (this.previewProductLine?.dock56) {
              this.dealWithFiveSixSetting({
                data: this.previewProductLine?.dock56,
              })
            }
          } else {
            this.$message({
              type: 'info',
              message: this.$t('dock56Drill.permissionErr'),
              duration: 1500,
              offset: 70,
            })
          }
        }
      )
    },
    handleGetLockStatus() {
      //TODO 进入页面，获取生产线设置锁的状态
      getLockStatus().then((res) => {
        // 拿到的锁状态赋值，当前是锁定还是解锁状态
        this.lockStatus = res?.data?.is_lock || 0
        this.tempStatus = res?.data?.is_lock || 0
      })
    },
    // 打开修改记录弹窗
    handleOpenChangeRecord() {
      this.showRecordModal = true
    },
    // 还原参数后的回调
    handleSuccess(newData) {
      const { data } = newData
      this.genFinalRecordFn(
        {
          ...data,
          selectedDockFile: data.dock5FDrill_fileType,
          dockType: data.dock5FDrill ? 'fiveDock' : 'sixDock',
        },
        this.oriFormData,
        newData,
        () => {
          this.$refs['recordModalRef']?.getData({ isReset: true })
        }
      )
    },

    // 初始化处理五六面钻设置
    dealWithFiveSixSetting(res) {
      this.ableSaveSetting = true
      this.dockType = res.data.dock5FDrill ? 'fiveDock' : 'sixDock'
      this.selectedOutput56DrillSoltDir = res.data.selectedOutput56DrillSoltDir
      this.is_output56FDrillLayoutDir = res.data.is_output56FDrillLayoutDir
      this.output56FDrillSlotDir = res.data.output56FDrillSlotDir
      this.long_edge_rotate_range = res.data.long_edge_rotate_range
      this.automation = res.data.automation
      this.output56FDrillDecimalNum = res.data.output56FDrillDecimalNum
      this.output56FDrillLayoutDir = res.data.output56FDrillLayoutDir
      this.output5FDrillSlotKnifeCode = res.data.output5FDrillSlotKnifeCode
      this.output5FDrillShapeKnifeCode = res.data.output5FDrillShapeKnifeCode
      this.output5FDrillNormalTexdirCode =
        res.data.output5FDrillNormalTexdirCode
      this.output5FDrillShapeKnifeOffset =
        res.data.output5FDrillShapeKnifeOffset
      this.output5FDrillReverseTexdirCode =
        res.data.output5FDrillReverseTexdirCode
      this.dock5FDrill_fileType = res.data.dock5FDrill_fileType
      this.dock6FDrill_fileType = res.data.dock6FDrill_fileType
      this.dock6FDrill_towSideConHole = res.data.dock6FDrill_towSideConHole
      this.dock56FDrill_twoSideConSlot = res.data.dock56FDrill_twoSideConSlot
      this.non_holes_not_files = res.data.non_holes_not_files
      this.dock6FDrill_code = res.data.dock6FDrill_code
      this.utf8bom = res.data.utf8bom
      this.dock6FDrill_plankHeight = res.data.dock6FDrill_plankHeight
      this.dock6FDrill_plankCode = res.data.dock6FDrill_plankCode
      this.dock6FOutputMprFile = res.data.dock6FOutputMprFile
      this.dock6FDrill_zValue = res.data.dock6FDrill_zValue
      this.dock56FDrillMprConHoleDir = res.data.dock56FDrillMprConHoleDir
      this.dock56FDrill_customFile = false
      this.old_file_type =
        this.dockType == 'fiveDock'
          ? res.data.dock5FDrill_fileType
          : res.data.dock6FDrill_fileType
      let selectedDockFile =
        this.dockType == 'fiveDock'
          ? this.dock5FDrill_fileType
          : this.dock6FDrill_fileType
      let data = this.dockFiveSixList.find(
        (v) => v.dock56Format == selectedDockFile
      )
      if (!data) {
        this.isNotOnline = true
        this.selectedDockFile = ''
        this.$message({
          message: this.$t('dock56Drill.fileTypeOffline'),
          type: 'info',
        })
      } else {
        this.selectedDockFile = selectedDockFile
      }
      this.opposite_file_identifier = res.data.opposite_file_identifier
      this.front_file_identifier = res.data.front_file_identifier
      this.is_front_edge_fixed = res.data.is_front_edge_fixed
      this.front_edge_fixed_direction = res.data.front_edge_fixed_direction
      this.isNotMergeSlot = res.data.isNotMergeSlot
      this.adjacentSize = res.data.adjacentSize ?? '3'
      ;(this.arc_description = res.data.arc_description),
        (this.split_slot = res.data.split_slot),
        (this.single_width = res.data.single_width)
      this.group_file = res.data.group_file === 'split_by_order'
    },
  },
  computed: {
    ...mapState('equipEnvironment', ['previewProductLine', 'previewInfo']),
    showSixDockSpecial() {
      const fileType = ['ban', 'mpr'].includes(this.selectedDockFile)
      return (
        this.dockType == 'sixDock' && !this.dock56FDrill_customFile && fileType
      )
    },
    showFiveDockSpecial() {
      const fileType = ['xml2', 'mpr'].includes(this.selectedDockFile)
      return (
        this.dockType == 'fiveDock' && !this.dock56FDrill_customFile && fileType
      )
    },
    // 是否选中默认模板
    showFiveSixDefault() {
      return ['xml2', 'mpr', 'dxf', 'ban'].includes(this.selectedDockFile)
    },
  },
  mounted() {
    this.handleGetLockStatus()
    if (this.$route.query.from) {
      if (this.$route.query.from == 'tiangong') {
        this.showTiangong = true
      }
    } else {
      this.showTiangong = false
      if (this.$route.query) {
        if (this.$route.query.setting_id) {
          this.getAllDock56File()
        }
      }
    }
  },
}
</script>

<style scoped lang="less">
/deep/.el-input {
  width: 50px;
}
.dock-five-six {
  .wrap {
    margin: 16px;
    overflow-y: auto;
    background: #fff;
    .equip-type {
      position: relative;
      .content {
        box-sizing: border-box;
        padding: 24px 24px 0 24px;
      }
      .useCustomFileSet {
        position: absolute;
        top: 10px;
        right: 40px;
      }
      .toCustomFile {
        position: absolute;
        top: 10px;
        right: 20px;
        color: #18a8c7;
        cursor: pointer;
      }
    }
    .file-format {
      .content {
        box-sizing: border-box;
        padding: 24px 24px 0 24px;
        .ant-select {
          width: 190px;
        }
        .remark-info {
          .note {
            color: #f00;
          }
        }
      }
      .not-online {
        margin-left: 16px;
        color: #f00;
        font-size: 14px;
      }
    }
    .common-setting {
      .content {
        padding: 24px 24px 0 24px;
        .ant-input {
          width: 80px;
        }
        .partition {
          margin-left: 15px;
        }
        .slot-tuglie {
          width: 380px;
        }
        .hintColor {
          color: #f00;
        }
        .check {
          margin: 0 20px 0 10px;
        }
        .drill-slot-dir {
          margin-left: 270px;
        }
        &.front-back-symbol {
          display: flex;
          align-items: center;
          > div {
            margin-right: 24px;
          }
          .ant-input {
            width: 192px;
          }
        }
        &.shape-texdir {
          .ant-radio-group .ant-radio-wrapper {
            margin-right: 85px;
          }
          .ant-radio-group .ant-radio-wrapper:first-child {
            margin-right: 0px;
          }
        }
      }
      .adjacent-size-transition-enter-active,
      .adjacent-size-transition-leave-active {
        transition: opacity 0.25s;
      }
      .adjacent-size-transition-enter,
      .adjacent-size-transition-leave-to {
        opacity: 0;
      }
    }
    .five-special-setting {
      .content {
        padding: 24px 24px 0 24px;
        > .small-title {
          margin-right: 16px;
        }
        .ant-input {
          width: 90px;
        }
        &.display {
          display: flex;
          align-items: center;
        }
        .tex-dir {
          margin-right: 16px;
        }
      }
    }
    .six-special-setting {
      .content {
        padding: 24px 24px 0 24px;
        .small-title {
          width: 220px;
        }
      }
    }
    .general-box {
      .content {
        padding: 24px 24px 0 24px;
        .small-title {
          width: 220px;
        }
      }
    }
  }
}
.tip {
  color: #f00;
}
</style>
